<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <vs-popup classContent="popup-example" title="Nouvelle marque" :active.sync="popupMarque">
          <div class="vx-row mb-6">

            <div class="vx-col w-full">
              <vs-input class="w-full" type="text" label="Libelle" v-validate="'required'" name="Libelle_marq" v-model="Libelle_marq" />
              <span class="text-danger text-sm"  v-show="errors.has('Libelle_marq')">{{ errors.first('Libelle_marq') }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col sm:w-2/3 w-full ml-auto">
              <vs-button class="mr-3 mb-2" @click="createMarque" :disabled="!validateForm">Enregistrer</vs-button>
              <vs-button color="warning" type="border" class="mb-2" @click="Libelle_marq = ''; ">Annuler</vs-button>
            </div>
          </div>
        </vs-popup>
        <vs-popup classContent="popup-example" title="Modifier une marque" :active.sync="popupMarqueUpdate">
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex items-end px-3">
                <feather-icon svgClasses="w-6 h-6" icon="PlusSquareIcon" class="mr-2" />
                <span class="font-medium text-lg leading-none">Marque Edit</span>
              </div>
              <vs-divider />
            </div>
          </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Libelle</span>
          </div>

          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" type="text" v-validate="'required'" name="Libelle_marq" v-model="Libelle_marq" />
            <span class="text-danger text-sm"  v-show="errors.has('Libelle_marq')">{{ errors.first('Libelle_marq') }}</span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="updateMarque" :disabled="!validateForm">Modifier</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="Libelle_marq = '';  popupMarqueUpdate = false;">Annuler</vs-button>
          </div>
        </div>
        </vs-popup>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table v-model="selected" stripe pagination max-items="50" search :data="marqueData">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button class="mb-4 ml-5 md:mb-0" @click="popupMarque=true">Nouvelle marque</vs-button>
              </template>
              <template slot="thead">
                <vs-th sort-key="email">
                  N°
                </vs-th>
                <vs-th sort-key="Libelle_marq">
                  Libelle
                </vs-th>
                <vs-th sort-key="website">
                  Action
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td :data="data[indextr]">
                    {{indextr + 1}}
                  </vs-td>

                  <vs-td :data="data[indextr].Libelle_marq">
                    {{data[indextr].Libelle_marq}}
                  </vs-td>

                  <vs-td :data="data[indextr].id">
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateMarqueShowForm(data[indextr])" />
                      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(data[indextr])" />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      popupMarque: false,
      popupMarqueUpdate: false,
      // status: false,
      Libelle_marq: '',
      selected:[],
      marques:[],
      marqueId: ''
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.Libelle_marq !== ''
    },
    marqueData () {
      return this.$store.state.marque.marques
    }
  },

  methods: {
    createMarque () {
      const payload = {
        Libelle_marq: this.Libelle_marq
      }

      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('marque/createMarque', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.resete_data()
          this.$vs.loading.close()
          this.popupMarque = false
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    confirmDeleteRecord (data) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer la marque "${data.Libelle_marq}"`,
        accept: this.deleteRecord(data),
        acceptText: 'Supprimer'
      })
    },
    deleteRecord (data) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('marque/dropMarque', data._id)
        .then(() => {
          this.showDeleteSuccess()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'danger',
        title: 'success',
        text: 'La marque selectionnée a bien été supprimée'
      })
    },
    getMarques () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('marque/getMarques')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    updateMarqueShowForm (data) {
      console.log('UpdatedMarque', data)
      this.Libelle_marq = data.Libelle_marq
      this.marqueId = data._id
      this.popupMarqueUpdate = true
    },
    updateMarque () {

      const payload = {
        Libelle_marq: this.Libelle_marq,
        id: this.marqueId
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('marque/updateMarque', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.resete_data()
          this.$vs.loading.close()
          this.popupMarqueUpdate = false
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    resete_data () {
      this.Libelle_marq = ''
    }
  },
  created () {
    this.getMarques()
  }
}
</script>

